import {BrowserRouter, Link, Navigate, Route, Routes, useLocation, useNavigate, useParams} from "react-router-dom";
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import Layout from "./components/organisms/Layout/Layout.jsx";
import SlideRoutes from 'react-slide-routes';

import GlobalStyle from '../src/styles/globals';
import {ThemeProvider} from "styled-components";
import defaultTheme from "./styles/defaultTheme.js";
import CameraPage from "./components/pages/CameraPage/CameraPage";
import HomePage from "./components/pages/HomePage/HomePage";
import FilterPage from "./components/pages/FilterPage/FilterPage.jsx";
import Camera from "./components/molecules/Camera/Camera.jsx";
import Placeholder from "./components/molecules/Placeholder/Placeholder.jsx";
import {CSSTransition, TransitionGroup} from "react-transition-group";
import React, {forwardRef, useEffect, useRef, useState} from "react";
import FinishedPage from "./components/pages/FinishedPage/FinishedPage.jsx";
import useApplicationState from "./utilities/ApplicationState/ApplicationState.jsx";
import FinishedImage from "./components/molecules/FinishedImage/FinishedImage.jsx";

export const base_url = import.meta.env.DEV ? "http://tkselfie.test" : "";

const PreviewContainer = styled.div`
  &.fade-enter,
  &.fade-exit {
    transition: opacity 500ms ease-out;
  }

  &.fade-enter {
    opacity: 0.01;
  }

  &.fade-enter.fade-enter-active {
    opacity: 1
  }

  &.fade-exit {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 1;
    z-index: 10;
  }

  &.fade-exit.fade-exit-active {
    opacity: 0;
  }
`;

const Preview = forwardRef(({ config, finish, selectedFilter, setEditedImage, entry, urlhash, lang }, cropperRef) => {
  const location = useLocation();

  const image = useApplicationState((state) => state.image);
  const setImage = useApplicationState((state) => state.setImage);
  const selectedDeviceId = useApplicationState((state) => state.selectedDeviceId);
  const setSelectedDeviceId = useApplicationState((state) => state.setSelectedDeviceId);
  const editedImage = useApplicationState((state) => state.editedImage);

  const camera = <Camera
    ref={cropperRef}
    image={image}
    setImage={setImage}
    selectedDeviceId={selectedDeviceId}
    setSelectedDeviceId={setSelectedDeviceId}
    selectedFilter={selectedFilter}
    setEditedImage={setEditedImage}
    config={config}
    finish={finish}
  />;

  return <TransitionGroup>
    <CSSTransition key={location.key} classNames="fade" timeout={1000}>
      <PreviewContainer>
        <Routes location={location}>
          <Route path="/:lang/" element={<Placeholder entry={entry}/>}/>
          <Route path="/:lang/camera" element={camera}/>
          <Route path="/:lang/filter" element={camera}/>
          <Route path="/:lang/finish" element={
            <FinishedImage image={editedImage} urlhash={urlhash} lang={lang}/>
          }/>
        </Routes>
      </PreviewContainer>
    </CSSTransition>
  </TransitionGroup>;
})

const Main = ({ config, urlhash }) => {
  const [conditions, setConditions] = useState(false);
  const lang = location.pathname.split('/')[1];
  const cropperRef = useRef(null);
  const [hash, setHash] = useState('');

  const image = useApplicationState((state) => state.image);
  const setImage = useApplicationState((state) => state.setImage);

  const cropperState = useApplicationState((state) => state.cropperState);
  const cropperTransitions = useApplicationState((state) => state.cropperTransitions);
  const cropperImage = useApplicationState((state) => state.cropperImage);
  const filterMenu = useApplicationState((state) => state.filterMenu);

  const selectedFilter = useApplicationState((state) => state.selectedFilter)
  const setSelectedFilter = useApplicationState((state) => state.setSelectedFilter);
  const setEditedImage = useApplicationState((state) => state.setEditedImage);

  const navigate = useNavigate();

  useEffect(() => {
    if(!selectedFilter && config && config[lang].filters) {
      setSelectedFilter(config[lang].filters[0]);
    }
  }, [config]);

  // useEffect(() => {
  //   if (config && lang) {
  //     document.title = config ? config[lang].Title : 'Loading';
  //   }
  // }, [lang, config]);

  if (!config) {
    return <div></div>;
  }

  const finish = () => {
    const canvas = cropperRef.current.getCanvas();
    if (canvas) {
      const newCanvas = document.createElement('canvas');
      const context = newCanvas.getContext('2d');

      const width = Math.min(canvas.width, 1000);
      const height = Math.min(canvas.height, 1000);

      //set dimensions
      newCanvas.width = width;
      newCanvas.height = height;

      //apply the old canvas to the new one
      context.drawImage(canvas, 0, 0, width, height);
      const img = new Image();
      img.setAttribute('crossorigin', 'anonymous');
      img.onload = function () {
        context.drawImage(img, 0, 0, width, height);
        setEditedImage(newCanvas.toDataURL());

        const newhash = uuidv4();
        setHash(newhash);

        const form = new FormData();
        // form.append("Name", "Noname"); // TODO
        // form.append("Email", "noname@test.de"); // TODO
        // form.append("Permission", false);
        form.append("Hash", newhash);
        form.append("action_submit", "Submit");
        newCanvas.toBlob((blob) => {
          if (blob) {
            form.append('Image', blob, `${newhash}.jpg`);
            fetch(`${base_url}/api/EntryForm`, {
              method: 'POST',
              body: form,
            }).then(() => {
              navigate(`/${lang}/finish?id=${newhash}`);
            });
          }
        }, 'image/jpeg');
      };
      img.src = selectedFilter.image;
    }
  }

  return (
    <Layout
      config={config}
      lang={lang}
      preview={
        <Preview
          config={config[lang]}
          finish={finish}
          ref={cropperRef}
          selectedFilter={selectedFilter}
          setEditedImage={setEditedImage}
          entry={config.entry}
          urlhash={urlhash}
          lang={lang}
        />
      }
    >
      <SlideRoutes>
        <Route path="/:lang/" element={
          <HomePage conditions={conditions} setConditions={setConditions} config={config[lang]}/>
        }/>
        <Route path="/:lang/camera" element={
          <CameraPage
            image={image}
            setImage={setImage}
            config={config[lang]}
            finish={finish}
            lang={lang}
          />
        }/>
        <Route path="/:lang/filter" element={
          <FilterPage
            image={image}
            cropperImage={cropperImage}
            cropperState={cropperState}
            cropperTransitions={cropperTransitions}
            filterMenu={filterMenu}
            config={config[lang]}
            finish={finish}
          />
        }/>
        <Route path="/:lang/finish" element={
          <FinishedPage config={config[lang]} lang={lang} hash={hash}/>
        }/>
        <Route path="/" element={
          <Navigate to="/de/"/>
        }/>
      </SlideRoutes>
    </Layout>
  )
}

const App = () => {
  const [config, setConfig] = useState(null);

  const search = /id=([^&]+)/.exec(location.href);
  const id = search ? search[1] : null;
  const url = id ? `${base_url}/api/conf/?id=${id}` : `${base_url}/api/conf/`;

  const setSelectedFilter = useApplicationState((state) => state.setSelectedFilter)

  useEffect(() => {
    fetch(url)
      .then((response) => response.json())
      .then((config) => {
        setConfig(config);
      });
  }, [])

  return (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyle/>
      <BrowserRouter>
        <Main config={config} urlhash={id}/>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default App;
