import styled from 'styled-components';
import { FixedCropper, ImageRestriction } from 'react-advanced-cropper'
import Square from "../../atoms/Square/Square.jsx";
import useApplicationState from "../../../utilities/ApplicationState/ApplicationState.jsx";
import 'react-advanced-cropper/dist/style.css';

import Overlay from "../../atoms/Overlay/Overlay.jsx";

import overlay from "../../../assets/filter/Gen_Vorlage8.png";
import {forwardRef, useEffect, useRef, useState} from "react";

const PreviewStyled = styled.div`
  display: flex;
  width: 100%;

  .react-transform-wrapper {
    height: 100%;
  }

  .advanced-cropper {
    height: 100%;
  }
`

const Preview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const ImageEditor = forwardRef(({ zoom }, ref) => {

  const image = useApplicationState((state) => state.image);
  const setCropperState = useApplicationState((state) => state.setCropperState);
  const setCropperTransitions = useApplicationState((state) => state.setCropperTransitions);
  const setCropperImage = useApplicationState((state) => state.setCropperImage);
  const selectedFilter = useApplicationState((state) => state.selectedFilter)

  const [isReady, setIsReady] = useState(false);

  const stencilSize = ({ boundary }) => {
    return {
      width: boundary.width,
      height: boundary.height,
    };
  };

  const defaultSize = ({ imageSize }) => {
    const size = Math.min(imageSize.width, imageSize.height);
    return {
      width: size,
      height: size,
    };
  };

  const onChange = (cropper) => {
    setCropperState(cropper.getState());
    setCropperImage(cropper.getImage());
    setCropperTransitions(cropper.getTransitions());
  };

  const localRef = useRef(null);

  useEffect(() => {
    // Manually set ref to fix empty ref bug
    ref.current = localRef.current;
  }, [localRef]);

  return <PreviewStyled test={isReady}>
    <Square>
      <FixedCropper
        src={image}
        defaultSize={defaultSize}
        stencilProps={{
          handlers: false,
          lines: false,
          movable: false,
          resizable: false,
        }}
        stencilSize={stencilSize}
        imageRestriction={ImageRestriction.stencil}
        sizeRestrictions={{
          // minWidth: 1000,
        }}
        onChange={onChange}
        // onReady={onChange}
        // onMoveEnd={onTransitionsChange}
        // onTransitionsEnd={onTransitionsChange}
        ref={localRef}
      />
      <Overlay src={selectedFilter.image}/>
    </Square>
  </PreviewStyled>;
});

export default ImageEditor;
