import { css } from "styled-components";
import calcrem from "../utilities/calcrem/calcrem";
import media from "../utilities/media";
/*
  font-weight: 300/600 only!
*/
const headline1 = css`
  font-family: ${props => props.theme.fonts.Headline};
  font-size: ${calcrem(36)};
  line-height: ${calcrem(44)};
  font-weight: normal;
  color: ${props => props.theme.colors.white};
  margin-bottom: 20px;

  ${media.moreThan("md")} {
    font-size: ${calcrem(44)};
    line-height: ${calcrem(50)};
    margin-bottom: 30px;
  }
`;

const subline = css`
  font-family: ${props => props.theme.fonts.Headline};
  font-size: ${calcrem(24)};
  line-height: ${calcrem(37)};
  font-weight: normal;
  color: ${props => props.theme.colors.white};
  margin-bottom: 20px;
  margin-top: -20px;

  ${media.moreThan("md")} {
    font-size: ${calcrem(27)};
    line-height: ${calcrem(40)};
    margin-top: -30px;
    margin-bottom: 30px;
  }
`;


const copytext = css`
  font-family: ${props => props.theme.fonts.Copy};
  font-size: ${calcrem(16)};
  line-height: ${calcrem(20)};
  color: ${props => props.theme.colors.text};

  a {
    color: ${props => props.theme.colors.white};
    text-decoration: none;
    border-bottom:1px solid #fff;
  }

  strong {
    font-weight: bold;
  }

  p {
  font-size: ${calcrem(16)};
  line-height: ${calcrem(20)};
    margin-bottom: 20px;
  }
  ${media.moreThan("md")} {
    font-size: ${calcrem(16)};
    line-height: ${calcrem(21)};
  }

  ul,
  ol {
    padding: 0 0 0 27px;
    li {
      list-style-type: disc;
      //color: ${props => props.theme.colors.primary};
    }
  }
`;

const inputtext = css`
  ${copytext};
  color: ${props => props.theme.colors.black};
`;

const footertext = css`
  font-family: ${props => props.theme.fonts.Copy};
  font-size: ${calcrem(16)};
  line-height: ${calcrem(20)};
  color: ${props => props.theme.colors.primary};
`

const buttontext = css`
  ${copytext};
  color: ${props => props.theme.colors.primary};
  font-weight: normal;
  text-decoration: none;
`;


export { headline1, subline, copytext, inputtext, buttontext, footertext};
