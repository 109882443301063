import font from '../assets/tktyperegular-regular.woff2';

const fonts = `
@font-face {
  font-family: TKType;
  font-style: normal;
  font-weight: normal;
  font-display: fallback;
  src: url(${font}) format('woff2'); /* Chrome 26+, Opera 23+, Firefox 39+ */
}`;

export default fonts;