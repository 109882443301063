import styled from 'styled-components';
import media from "../../../utilities/media.js";

const ButtonRowStyled = styled.div`
  padding: 10px 20px 10px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 10px;

  // ${media.lessThan("lg")} {
  //   background-color: ${props => props.theme.colors.white};
  //   position: fixed;
  //   bottom: 0;
  //   left: 0;
  //   right: 0;
  //   justify-content: flex-end;
  //   flex-wrap: nowrap;
  //   padding: 10px 20px;
  // }

  ${media.moreThan("lg")} {
    ${props => props.onlymobile && `display: none;`}
  }
`

const ButtonRow = ({ children, onlyMobile }) => {
  return <ButtonRowStyled onlymobile={onlyMobile}>
    {children}
  </ButtonRowStyled>
}

export default ButtonRow;