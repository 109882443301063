const defaultTheme = {
  colors: {
    black: "#333",
    white: "#fff",
    whiteTrans: "rgba(255,255,255,0.9)",
    grey: " #EDEDED",
    darkGrey: " #999",
    text: "#ffffff",
    primary: "#00a0f0",
    background: "#00a0f0",
    highlight: "#ffbf26",
  },
  fonts: {
    Headline: "TKType, Helvetica, Arial, sans-serif",
    Button: "TKType, Helvetica, Arial, sans-serif",
    Copy: "TKType, Helvetica, Arial, sans-serif"
  }
};

export default defaultTheme;
