import styled from 'styled-components';
import {copytext} from "../../../styles/typography.js";

const TextStyled = styled.div`
  ${copytext}
`

const Text = ({ content }) => {
  return <TextStyled dangerouslySetInnerHTML={{__html: content}}/>
}

export default Text;