import styled from 'styled-components';
import { ReactComponent as CameraIcon } from '../../../assets/icon_kamera.svg';
import { ReactComponent as RepeatIcon } from '../../../assets/icon_back.svg';
import { ReactComponent as MinusIcon } from '../../../assets/icon_minus.svg';
import { ReactComponent as PlusIcon } from '../../../assets/icon_plus.svg';


const IconStyled = styled.div`
  svg {
    display: block;
  }
  polygon, path{
    ${props => `fill: ${props.color};`};
  }
`

const iconMap = {
  camera: CameraIcon,
  repeat: RepeatIcon,
  minus: MinusIcon,
  plus: PlusIcon,
}

const Icon = ({ id, color }) => {
  const Icon = iconMap[id];
  return <IconStyled color={color}>
    <Icon />
  </IconStyled>
}

export default Icon;
