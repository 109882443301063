import styled from 'styled-components';
import {headline1} from "../../../styles/typography.js";

const HeadlineStyled = styled.h1`
  ${headline1}
`

const Headline = ({ children }) => {
  return <HeadlineStyled>
    {children}
  </HeadlineStyled>
}

export default Headline;