import styled from 'styled-components';

const ImageStyled = styled.img`
  display: block;
  width: 100%;
`

const Image = ({ src, alt }) => {
  return <ImageStyled src={src} alt={alt}/>;
}

export default Image;