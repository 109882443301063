import styled from 'styled-components';
import {subline} from "../../../styles/typography.js";

const SublineStyled = styled.h1`
  ${subline}
`

const Subline = ({ children }) => {
  return <SublineStyled>
    {children}
  </SublineStyled>
}

export default Subline;
