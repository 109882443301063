import styled from 'styled-components';
import FilterPreview from "../../atoms/FilterPreview/FilterPreview";
import media from "../../../utilities/media.js";
import useApplicationState from "../../../utilities/ApplicationState/ApplicationState.jsx";

const FilterNavStyled = styled.div`
  position: absolute;
  width: 100vw;
  overflow: auto;
  z-index: 2;
  left: 0;

  ${media.moreThan("lg")} {
    position: static;
    width: 100%;
  }
`;

const FilterPlaceholder = styled.div`
  width: 100%;
  margin-top: 40px;
  height: 22vw;
`;

const FilterNavContent = styled.div`
  display: flex;

  ${media.moreThan("lg")} {
    flex-wrap: wrap;
  }
`;

const FilterNavItem = styled.div`
  width: 22vw;
  height: 22vw;
  flex: 0 0 auto;
  overflow: hidden;
  position: relative;
  display: flex;

  ${props => props.selected ? `border: 2px solid white;` : `border: 2px solid ${props.theme.colors.primary};`}
  ${media.moreThan("lg")} {
    width: 20%;
    height: 0;
    padding-bottom: 20%;
  }
`;

const FilterNav = ({image, state, transitions, filters = []}) => {
  const selectedFilter = useApplicationState((state) => state.selectedFilter);
  const setSelectedFilter = useApplicationState((state) => state.setSelectedFilter);

  return <>
    <FilterNavStyled>
      <FilterNavContent>
        {filters.map((filter, index) => (
          <FilterNavItem selected={selectedFilter === filter} onClick={() => setSelectedFilter(filter)}
                         key={`key-${index}`}>
            <FilterPreview
              image={image}
              state={state}
              transitions={transitions}
              overlay={filter.image}
            />
          </FilterNavItem>
        ))}
      </FilterNavContent>
    </FilterNavStyled>
    <FilterPlaceholder />
  </>
}

export default FilterNav;