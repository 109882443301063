import create from 'zustand'

const useApplicationState = create((set) => ({
  conditions: false,
  setConditions: (conditions) => set({ conditions }),
  image: null,
  setImage: (image) => set({ image }),
  selectedDeviceId: undefined,
  setSelectedDeviceId: (selectedDeviceId) => set({ selectedDeviceId }),
  cropperState: null,
  setCropperState: (cropperState) => set({ cropperState }),
  cropperTransitions: null,
  setCropperTransitions: (cropperTransitions) => set({ cropperTransitions }),
  cropperImage: null,
  setCropperImage: (cropperImage) => set({ cropperImage }),
  filterMenu: [],
  setFilterMenu: (filterMenu) => set({ filterMenu }),
  selectedFilter: null,
  setSelectedFilter: (selectedFilter) => set({ selectedFilter }),
  zoom: 1,
  setZoom: (zoom) => set({ zoom }),
  editedImage: null,
  setEditedImage: (editedImage) => set({ editedImage }),
}));

export default useApplicationState;
