import styled from 'styled-components';
import Header from "../Header/Header.jsx";
import Footer from "../Footer/Footer.jsx";
import media from "../../../utilities/media";

const LayoutStyled = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${props => props.theme.colors.background};
  width: 100%;
`;

const MainStyled = styled.div`
  flex: 1 0 auto;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
`;

const MainContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${media.moreThan("lg")} {
    flex-direction: row;
    align-items: stretch;
  }
`;

const Content = styled.div`
  padding: 0 20px;
  ${media.moreThan("lg")} {
    width: 50%;
    display: flex;
    align-items: stretch;
  }
`;

const Preview = styled.div`
  position: relative;
  
  ${media.moreThan("lg")} {
    width: 50%;
    order: 2;
    padding-right: 20px;
  }
`;

const Layout = ({ children, preview, config, lang }) => {
  return <LayoutStyled>
    <Header config={config} lang={lang}/>
    <MainStyled>
      <MainContent>
        <Preview>
          {preview}
        </Preview>
        <Content>
          {children}
        </Content>
      </MainContent>
    </MainStyled>
    <Footer config={config[lang]}/>
  </LayoutStyled>
}

export default Layout;