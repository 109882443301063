import styled from 'styled-components';
import Headline from "../../atoms/Headline/Headline.jsx";
import Text from "../../atoms/Text/Text.jsx";
import Content from "../../atoms/Content/Content.jsx";
import {useEffect} from "react";
import ButtonRow from "../../atoms/ButtonRow/ButtonRow.jsx";
import UploadButton from "../../atoms/UploadButton/UploadButton";
import {useNavigate} from "react-router-dom";


const CameraPageStyled = styled.div`
`

const CameraPage = ({ image, setImage, config, lang }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if(image) {
      setImage(null);
    }
  }, []);

  return <CameraPageStyled>
    <Content>
      <Headline>{ config.CameraTitle }</Headline>
      <Text content={config.CameraContent} />
    </Content>
    <ButtonRow>
      <UploadButton onChange={(image) => {
        setImage(image);
        navigate(`/${lang}/filter`);
      }} title={ config.ButtonUpload } lang={lang}/>
    </ButtonRow>
  </CameraPageStyled>
}

export default CameraPage;