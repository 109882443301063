import styled from 'styled-components';
import {footertext} from "../../../styles/typography.js";
import Text from "../../atoms/Text/Text.jsx";
import {Link} from "react-router-dom";
import logo from "../../../assets/thyssenkrupp_logo_blue.svg";
import media from "../../../utilities/media.js";

const FooterStyled = styled.div`
  display: flex;
  background-color: ${props => props.theme.colors.white};
  padding: 20px;
`

const FooterText = styled.div`
  position: relative;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding: 0;

  p {
    ${footertext};
    margin-bottom: 0;

    a {
      ${footertext};
      white-space: nowrap;
    }
  }
`;

const Logo = styled(Link)`
  display: none;
  height: 100%;
  position: absolute;
  top:0;
  right:0;

  ${media.moreThan("md")} {
    display: block;
  }

  img {
    display: block;
    height: 100%;
  }
`;

const Footer = ({config}) => {
  return <FooterStyled>
    <FooterText>
      <Text content={config?.FooterText}/>
      <Logo to="/">
        <img src={logo} alt="Thyssenkrupp Logo"/>
      </Logo>
    </FooterText>
  </FooterStyled>
}

export default Footer;
