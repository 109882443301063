import styled from 'styled-components';
import logo from '../../../assets/thyssenkrupp_logo_min.svg';
import {Link} from "react-router-dom";
import media from "../../../utilities/media.js";
import {copytext} from "../../../styles/typography.js";

const HeaderStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  padding: 10px 20px;
  margin: 0 auto;
  height: 80px;

  ${media.moreThan("md")} {
    height: 100px;
    padding: 20px;
  }
`

const Logo = styled(Link)`
  height: 100%;

  img {
    display: block;
    height: 100%;
  }
`;

const Languages = styled.div`
`;

const LanguageLink = styled.a`
  ${copytext};
  text-transform: uppercase;
  text-decoration: none;
  padding: 10px;
  border: 1px solid ${props => props.theme.colors.white};
  transition: all 0.3s ease-in-out;

  &:hover {
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.primary};
  }
`;

const Header = ({config, lang}) => {
  const languages = config.languages;
  return <HeaderStyled>
    <Logo to="/">
      <img src={logo} alt="Thyssenkrupp Logo"/>
    </Logo>
    <Languages>
      {Object.entries(languages).map(([language, title], index) => (
        language !== lang ? <LanguageLink href={`/${language}`} key={`lang-${index}`}>{language}</LanguageLink> : null
      ))}
    </Languages>
  </HeaderStyled>
}

export default Header;
