import styled from 'styled-components';

const ContentStyled = styled.div`
  padding-top: 20px;
`

const Content = ({ children }) => {
  return <ContentStyled>
    {children}
  </ContentStyled>
}

export default Content;