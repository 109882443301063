import styled from 'styled-components';
import {buttontext} from "../../../styles/typography.js";
import Square from "../../atoms/Square/Square.jsx";
import {Navigate} from "react-router-dom";
import media from "../../../utilities/media.js";

const FinishedImageStyled = styled.div`
  background-color: ${props => props.theme.colors.white};
  border-radius: 0;
  ${buttontext};
  margin-bottom: 20px;

  ${media.moreThan("lg")} {
    margin-bottom: 50px;
  }
`

const FinishedImageImage = styled.img`
  display: block;
  width: 100%;
`;

const FinishedImage = ({image, lang, urlhash}) => {
  return image ? (
    <FinishedImageStyled>
      <Square>
        <FinishedImageImage src={image}/>
      </Square>
    </FinishedImageStyled>
  ) : (
    <Navigate to={`/${lang}/${urlhash ? `?id=${urlhash}` : ''}`}/>
  )
}

export default FinishedImage;