import styled from 'styled-components';
import {buttontext} from "../../../styles/typography.js";
import media from "../../../utilities/media.js";
import {useNavigate} from "react-router-dom";

const ButtonStyled = styled.label`
  padding: 10px 20px;
  background-color: ${props => props.theme.colors.white};
  border-radius: 0;
  ${buttontext};
  position: relative;
  cursor: pointer;

  &:hover {
    color: ${props => props.theme.colors.black};
  }
  
  input {
    position: absolute;
    left: -1000px;
  }

  // ${media.lessThan("lg")} {
  //   background-color: ${props => props.theme.colors.primary};
  //   color: ${props => props.theme.colors.white};
  //
  //   ${props => props.disabled && `
  //     background-color: ${props.theme.colors.grey};
  //     color: ${props.theme.colors.darkGrey};
  //   `}
  // }
`

const Button = ({ title, onChange }) => {
  const handleChangeImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      const dataURL = e.target.result;
      onChange(dataURL);
    }
    reader.readAsDataURL(file);
  }

  return <ButtonStyled>
    <input type="file" name="file"
           className="upload-file"
           id="file"
           onChange={handleChangeImage}
           />
    {title}
  </ButtonStyled>
}

export default Button;