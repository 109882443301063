import styled from 'styled-components';
import {copytext} from "../../../styles/typography.js";
import media from "../../../utilities/media.js";

const CheckboxContentStyled = styled.div`
  flex: 1 1 auto;
  //margin-bottom: 20px;

  ${media.moreThan("lg")} {
    width: 100%;
  }
`

const FakeCheckbox = styled.div`
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
    // border: 1px solid ${props => props.theme.colors.primary};
  //
    // ${media.moreThan("lg")} {
    //   background-color: ${props => props.theme.colors.white};
  // }

  background-color: ${props => props.theme.colors.white};

  &:before, &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 10px;
    height: 20px;
    border-right: 1px solid ${props => props.theme.colors.primary};
    transform-origin: center right;
    transform: rotate(45deg);
    display: none;
  }

  &:after {
    transform: rotate(-45deg);
  }
`;

const CheckboxStyled = styled.input`
  display: none;

  &:checked + label ${FakeCheckbox} {
    &:before, &:after {
      display: block;
    }
  }
`;

const LabelStyled = styled.label`
  display: flex;
  width: 100%;
  padding-left: 30px;
  position: relative;
  ${copytext};
  cursor: pointer;

  ${media.moreThan("lg")} {
    padding-right: 30px;
  }
`;

const LabelContentStyled = styled.div`
  flex: 0 1 auto;
`;

const LabelCheck = styled.div`
  width: 40px;
  height: 40px;
  flex: 0 0 auto;
  margin-left: auto;

  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #fff;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
  }

  .checkmark {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #00a0f0;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #fff;
    animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
  }

  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0
    }
  }
  @keyframes scale {
    0%, 100% {
      transform: none
    }
    50% {
      transform: scale3d(1.1, 1.1, 1)
    }
  }
  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px #fff
    }
  }
`;


const FancyCheckbox = ({name, label, checked, onChange}) => {
  return <CheckboxContentStyled>
    <CheckboxStyled type="checkbox" name={name} id={name} value={1} checked={checked} onChange={onChange}/>
    <LabelStyled htmlFor={name}>
      <FakeCheckbox/>
      <LabelContentStyled dangerouslySetInnerHTML={{__html: label}}/>
      {checked ? (
        <LabelCheck>
          <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
            <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
          </svg>
        </LabelCheck>
        ) : (
          <LabelCheck/>
        )}
    </LabelStyled>
  </CheckboxContentStyled>
}

export default FancyCheckbox;
