import styled from 'styled-components';

const SquareStyled = styled.div`
  width: 100%;
  padding-bottom: 100%;
  position: relative;
  ${props => props.background && `background-color: ${props.background};`}
`

const SquareContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Square = ({ children, background, className }) => {
  return <SquareStyled background={background} className={className}>
    <SquareContent>
      { children }
    </SquareContent>
  </SquareStyled>;
}

export default Square;