import styled from 'styled-components';
import {Link} from "react-router-dom";
import {buttontext} from "../../../styles/typography.js";
import media from "../../../utilities/media.js";

const ButtonStyled = styled(Link)`
  padding: 10px 20px;
  border-radius: 0;
  ${buttontext};
  transition: color 0.3s ease-in-out;
  background-color: ${props => props.theme.colors.white};
  white-space: nowrap;
  
  ${props => props.primary && `
    background-color: ${props.theme.colors.highlight};
    color: ${props.theme.colors.white};
  `};

  &:hover {
    color: ${props => props.theme.colors.black};
  }

  // ${media.lessThan("lg")} {
  //   background-color: ${props => props.theme.colors.primary};
  //   color: ${props => props.theme.colors.white};
  //
  //   ${props => props.primary && `
  //     background-color: ${props.theme.colors.highlight};
  //     color: ${props.theme.colors.white};
  //   `};
  //
  //   ${props => props.disabled && `
  //     background-color: ${props.theme.colors.grey};
  //     color: ${props.theme.colors.darkGrey};
  //   `}
  // }

  ${props => props.disabled && `
    background-color: ${props.theme.colors.grey};
    color: ${props.theme.colors.darkGrey};
    
    &:hover {
      color: ${props.theme.colors.darkGrey};
    }
  `}
`

const ButtonLink = ({children, to, disabled, primary}) => {
  return <ButtonStyled
    primary={primary}
    to={to}
    disabled={disabled}
    onClick={(e) => {
      if (disabled) {
        e.preventDefault();
      }
    }}>
    {children}
  </ButtonStyled>
}

export default ButtonLink;