interface IMap {[index: string]: string};
interface INumMap {[index: string]: number};

export default class MediaQueryGenerator {
  breakpointMap: IMap;
  maxBreakpointMap: IMap;
  breakpointMapPx: INumMap;

  constructor(breakpointMap:IMap, maxBreakpointMap: IMap, breakpointMapPx: INumMap) {
    this.breakpointMap = breakpointMap;
    this.maxBreakpointMap = maxBreakpointMap;
    this.breakpointMapPx = breakpointMapPx;
  }

  moreThan(breakpoint: string) {
    return `@media(min-width: ${this.breakpointMap[breakpoint]})`;
  }

  lessThan(breakpoint: string) {
    return `@media(max-width: ${this.maxBreakpointMap[breakpoint]})`;
  }

  between(breakpoint1: string, breakpoint2: string) {
    return `@media(min-width: ${this.breakpointMap[breakpoint1]}) and (max-width: ${this.maxBreakpointMap[breakpoint2]})`;
  }

  isMoreThan(breakpoint: string) {
    if (typeof window !== 'undefined') {
      return window.innerWidth >= this.breakpointMapPx[breakpoint];
    }
  }
}
