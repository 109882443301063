import styled from 'styled-components';
import Icon from "../Icon/Icon.jsx";

const ButtonStyled = styled.button`
  -webkit-appearance: none;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: none;
  background-color: ${props => props.theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  cursor:pointer;
`

const Button = ({ icon, onClick }) => {
  return <ButtonStyled onClick={() => onClick()}>
    <Icon id={icon}/>
  </ButtonStyled>
}

export default Button;