import styled from 'styled-components';
import {buttontext} from "../../../styles/typography.js";
import Icon from "../Icon/Icon.jsx";
import media from "../../../utilities/media.js";

const ButtonRowStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);

`

const ButtonRow = ({ children }) => {
  return <ButtonRowStyled>
    {children}
  </ButtonRowStyled>
}

export default ButtonRow;