import styled from 'styled-components';
import {buttontext} from "../../../styles/typography.js";

const ButtonStyled = styled.button`
  position: relative;
  padding: 10px 20px;
  border: none;
  background-color: ${props => props.theme.colors.white};
  border-radius: 0;
  margin: 0;
  cursor: pointer;
  ${buttontext};

  ${props => props.primary && `
    background-color: ${props.theme.colors.highlight};
    color: ${props.theme.colors.white};
  `};

  ${props => props.disabled && `
    background-color: ${props.theme.colors.grey};
    color: ${props.theme.colors.white};
  `};
`

const Spinner = styled.div`
  display: inline-block;
  position: absolute;
  top: 5px;
  right: -25px;
  width: 20px;
  height: 20px;

  &:after {
    content: " ";
    display: block;
    width: 14px;
    height: 14px;
    margin: 8px;
    border-radius: 50%;
    border: 2px solid #fff;
    border-color: #fff transparent #fff transparent;
    animation: lds-dual-ring 1.2s linear infinite;
  }

  @keyframes lds-dual-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

`;

const Button = ({ children, onClick, primary, loading=false }) => {
  return <ButtonStyled onClick={() => onClick()} primary={primary} disabled={loading}>
    {children}
    {loading && <Spinner/>}
  </ButtonStyled>
}

export default Button;
