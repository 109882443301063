import styled from 'styled-components';
import {CropperPreview} from "react-advanced-cropper";
import Overlay from "../Overlay/Overlay.jsx";
import media from "../../../utilities/media.js";

const FilterPreviewStyled = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  
  img {
    display: block;
  }

  // ${media.moreThan("lg")} {
  //   width: 120px;
  //   height: 120px;
  // }
`

const CropperPreviewStyled = styled(CropperPreview)`
  width: 100%;
  height: 100%;
`;

const FilterPreview = ({ image, state, transitions, overlay }) => {
  return <FilterPreviewStyled>
    <CropperPreviewStyled
      className="preview"
      image={image}
      state={state}
      transitions={transitions}
    />
    <Overlay src={overlay} />
  </FilterPreviewStyled>
}

export default FilterPreview;